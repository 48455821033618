// GENERAL RESETS
//////////////////////////////
* {
    margin: 0;
    padding: 0;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;

    &, &:before, &:after {
        box-sizing: border-box;
    }
}

*:focus {
    outline: none;
}

// SELECTION APPEARANCE
//////////////////////////////
::-webkit-scrollbar {
    width: rem(10px);
}

::-webkit-scrollbar-track {
    background: #E9E9E9;
    border-radius: rem(4px);
}

::-webkit-scrollbar-thumb {
    background: rgba($dark, .3);
    border-radius: rem(6px);
    border: 2px solid #e9e9e9;
}

// HTML BASICS
//////////////////////////////
html, body {
    width: 100% !important;
    max-width: 100% !important;
    overflow-x: hidden;
}

html {
    box-sizing: border-box;
    color: $dark;
    font-family: $mainFont;
    font-size: 100% * ($baseFontSize / 16px);
    font-weight: 300;
    hyphens: manual;
    line-height: rem($baseLineHeight);
    text-size-adjust: none;
}

body {
    line-height: rem($baseLineHeight);
    background: $light;
    opacity: 1 !important;
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;

        &.naviOpen {
            height: 100%;
            overflow: hidden;
        }

        &.noJS * {
            transition: none !important;

        &::before, &::after {
            transition: none !important;
        }
    }
}

strong {
    font-weight: 800;
}

// GENERAL LINKS
//////////////////////////////
a {
    color: $text;
    text-decoration: none;
    transition: color $transition;

    &:hover, &:focus {
        color: rgba($text, .65);
    }
}

// GENERAL ICONS
//////////////////////////////
i {
    font-family: $iconFont;
    font-weight: 300;
    color: $light;
}

// LIST STYLES
//////////////////////////////
ul {
    list-style: none;
}