#root {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../images/bg-grunge.jpg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    overflow: hidden;
}

.App {
    position: relative;
    background: $lightGray;
    width: calc(100% - #{rem(60px)});
    padding: rem(50px) rem(40px);
    margin: rem(30px);
    border-radius: rem(12px);

    @include breakpoint(small) {
        width: calc(100% - #{rem(90px)});
        padding: rem(60px) rem(50px);
        margin: rem(45px);
    }

    @include breakpoint(large) {
        width: calc(100% - #{rem(160px)});
        padding: rem(75px) rem(65px);
        margin: rem(80px);
    }

    @include breakpoint(giant) {
        width: calc(100% - #{rem(200px)});
        padding: rem(85px) rem(75px);
        margin: rem(100px);
    }

    @include breakpoint(huge) {
        width: 80%;
        max-width: rem(1075px);
        padding: rem(105px) rem(95px);
        margin: rem(60px) 0;
    }

    .Schrottwichteln {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h1 {
        font-size: rem(30px);
        line-height: 1em;
        font-weight: 200;
        margin-bottom: rem(20px);
        text-align: center;

        @include breakpoint(tiny) {
            font-size: rem(40px);
            margin-bottom: rem(30px);
        }
        .name {
            display: block;
            font-size: rem(34px);
            line-height: 1em;
            font-weight: 600;
            padding-top: rem(5px);

            @include breakpoint(tiny) {
                font-size: rem(45px);
            }
        }
    }

    .beschreibung {
        display: block;
        font-size: rem(16px);
        line-height: 1.6667em;
        max-width: 90%;
        margin-bottom: rem(90px);
        text-align: center;

        @include breakpoint(tiny) {
            font-size: rem(18px);
            margin-bottom: rem(110px);
        }

        @include breakpoint(small) {
            margin-bottom: rem(130px);
        }
    }

    .zettelHolder {
        position: relative;
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 36%;
        margin: 0 auto;

        @include breakpoint(small) {
            width: 80%;
            padding-bottom: 28.8%;
        }

        @include breakpoint(medium) {
            width: 70%;
            padding-bottom: 25.2%;
        }

        @include breakpoint(large) {
            width: 50%;
            padding-bottom: 18%;
        }

        &.clicked {

            .zettelTop {
                color: transparent;
                transform: rotateX(130deg);
                transition: transform .75s ease-in-out, color .01s ease-in-out .35s;
                transform-origin: 0% 0%;
            }
        }

        .zettelBottom, .zettelTop {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-image: url("../images/bg-letter.jpg");
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            font-size: rem(18px);
            line-height: 1em;
            font-weight: 600;

            @include breakpoint(tiny) {
                font-size: rem(22px);
            }

            @include breakpoint(medium) {
                font-size: rem(26px);
            }

            @include breakpoint(huge) {
                font-size: rem(30px);
            }
        }

        .zettelTop {
            z-index: 1;
            user-select: none;
        }

        .zettelBottom {
            border-top: solid black 1px;
        }
    }
}