/* Plus Jakarta Sans | Extra Light */
@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/PlusJakartaSans-ExtraLight.woff2') format('woff2'),
         url('./fonts/PlusJakartaSans-ExtraLight.woff') format('woff');
         font-weight: 200;
         font-style: normal;
         font-display: swap;
}

/* Plus Jakarta Sans | Extra Light Italic */
@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/PlusJakartaSans-ExtraLightItalic.woff2') format('woff2'),
         url('./fonts/PlusJakartaSans-ExtraBoldItalic.woff') format('woff');
         font-weight: 200;
         font-style: italic;
         font-display: swap;
}

/* Plus Jakarta Sans | Light */
@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/PlusJakartaSans-Light.woff2') format('woff2'),
         url('./fonts/PlusJakartaSans-Light.woff') format('woff');
         font-weight: 300;
         font-style: normal;
         font-display: swap;
}

/* Plus Jakarta Sans | Light Italic */
@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/PlusJakartaSans-LightItalic.woff2') format('woff2'),
         url('./fonts/PlusJakartaSans-LightItalic.woff') format('woff');
         font-weight: 300;
         font-style: italic;
         font-display: swap;
}

/* Plus Jakarta Sans | Regular */
@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/PlusJakartaSans-Regular.woff2') format('woff2'),
         url('./fonts/PlusJakartaSans-Regular.woff') format('woff');
         font-weight: 400;
         font-style: normal;
         font-display: swap;
}

/* Plus Jakarta Sans | Regular Italic */
@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/PlusJakartaSans-Italic.woff2') format('woff2'),
         url('./fonts/PlusJakartaSans-Italic.woff') format('woff');
         font-weight: 400;
         font-style: italic;
         font-display: swap;
}

/* Plus Jakarta Sans | Medium */
@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/PlusJakartaSans-Medium.woff2') format('woff2'),
         url('./fonts/PlusJakartaSans-Medium.woff') format('woff');
         font-weight: 500;
         font-style: normal;
         font-display: swap;
}

/* Plus Jakarta Sans | Medium Italic */
@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/PlusJakartaSans-MediumItalic.woff2') format('woff2'),
         url('./fonts/PlusJakartaSans-MediumItalic.woff') format('woff');
         font-weight: 500;
         font-style: italic;
         font-display: swap;
}

/* Plus Jakarta Sans | SemiBold */
@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/PlusJakartaSans-SemiBold.woff2') format('woff2'),
         url('./fonts/PlusJakartaSans-SemiBold.woff') format('woff');
         font-weight: 600;
         font-style: normal;
         font-display: swap;
}

/* Plus Jakarta Sans | SemiBold Italic */
@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/PlusJakartaSans-SemiBoldItalic.woff2') format('woff2'),
         url('./fonts/PlusJakartaSans-SemiBoldItalic.woff') format('woff');
         font-weight: 600;
         font-style: italic;
         font-display: swap;
}

/* Plus Jakarta Sans | Bold */
@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/PlusJakartaSans-Bold.woff2') format('woff2'),
         url('./fonts/PlusJakartaSans-Bold.woff') format('woff');
         font-weight: 700;
         font-style: normal;
         font-display: swap;
}

/* Plus Jakarta Sans | Bold Italic */
@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/PlusJakartaSans-BoldItalic.woff2') format('woff2'),
         url('./fonts/PlusJakartaSans-BoldItalic.woff') format('woff');
         font-weight: 700;
         font-style: italic;
         font-display: swap;
}

/* Plus Jakarta Sans | ExtraBold */
@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/PlusJakartaSans-ExtraBold.woff2') format('woff2'),
         url('./fonts/PlusJakartaSans-ExtraBold.woff') format('woff');
         font-weight: 800;
         font-style: normal;
         font-display: swap;
}

/* Plus Jakarta Sans | ExtraBold Italic */
@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./fonts/PlusJakartaSans-ExtraBoldItalic.woff2') format('woff2'),
         url('./fonts/PlusJakartaSans-ExtraBoldItalic.woff') format('woff');
         font-weight: 800;
         font-style: italic;
         font-display: swap;
}