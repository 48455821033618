.title {
    font-size: rem(12px);
    line-height: 1em;
    letter-spacing: .2em;
    color: $lightText;
    text-transform: uppercase;
}

.divider {
    color: $lightText;
    padding: 0 rem(6px);
}

.specialButton {
    display: flex;
    background-color: $alert;
    border: 0;
    border-radius: rem(5px);
    height: rem(29px);
    margin-top: rem(10px);

    i {
        font-size: rem(13px);
        line-height: 1.1076em;
        padding: rem(9px) rem(8.5px) rem(8px) rem(11px);
        background-color: rgba(35, 35, 35, .37);
        border-radius: rem(5px) 0 0 rem(5px);
    }

    span {
        font-size: rem(14px);
        line-height: 1em;
        font-weight: 600;
        color: $lightestGray;
        padding: rem(7.5px) rem(10px) rem(7.5px) rem(8px);
        border-radius: 0 rem(5px) rem(5px) 0;
    }
}